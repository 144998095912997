import React from 'react'
import { graphql } from 'gatsby'
import { Intro, Layout } from '@components'
import BlockWrapper from '@pageBuilder/wrapper'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Post = ({ data, pageContext }) => {
  const { moduleArea, seoMetaTags, title, meta, categories } = data.post
  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Intro
        breadcrumbs={pageContext?.breadcrumbs}
        title={title}
        date={meta.firstPublishedAt}
        categories={categories}
      />
      <BlockWrapper blocks={moduleArea} />
    </Layout>
  )
}

export const query = graphql`
  query postById($id: String!) {
    post: datoCmsPost(id: { eq: $id }) {
      id
      path
      slug
      title
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "550", fit: "crop", w: "1600", q: 60 }
        )
        alt
      }
      meta {
        firstPublishedAt(formatString: "DD MMMM YYYY")
      }
      categories {
        id
        title
        slug
      }
      moduleArea {
        ... on DatoCmsAccordion {
          ...Accordion
        }
        ... on DatoCmsVideoBanner {
          ...BannerVideo
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsCardGrid {
          ...CardGrid
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsImageGrid {
          ...ImageGrid
        }
        ... on DatoCmsImageWithContent {
          ...ImageWithContent
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Post
